import {trigger} from "../utils/Trigger";

export default class Input {

    constructor(input) {
        if (input.__InputInstance__ instanceof Input) {
            return input.__InputInstance__;
        }

        if (input.type === 'hidden' && !input.classList.contains('datepicker')) {
            throw new Error('Input type hidden cannot be instanciate');
        }

        this.input   = input;
        this.wrapper = this.input.parentNode;
        this.label   = this.wrapper.querySelector('label');

        this.listen();

        this.input.__InputInstance__ = this;
    }

    listen() {
        $(this.wrapper)
            .on('mousedown', 'label', this.mousedown.bind(this))

            .on('click', 'label', this.click.bind(this))

            .on('focus', 'input', this.focus.bind(this))

            .on('blur', 'input', this.blur.bind(this))

            .on('change', 'input', this.change.bind(this));
    }

    mousedown() {
        if (this.wrapper.classList.contains('focus')) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }

    click() {
        trigger(this.input, 'focus');
    }

    focus() {
        if (!this.input.disabled) {
            this.wrapper.classList.add('focus');
        }
    }

    blur() {
        this.wrapper.classList.remove('focus');
    }

    change() {
        this.wrapper.classList[this.input.value !== '' ? 'add' : 'remove']('fullfilled');
    }
}
