export class TableLink {

    constructor(element) {
        if (!element) {
            return;
        }

        this.element = element;

        this.listen();
    }

    listen() {
        this.element.addEventListener('click', (e) => {
            if (e.target.classList.contains('table-body__detail')) {
               const parent = e.target.parentNode;
               const id = parent.getAttribute('data-id');
               window.location.pathname = '/operation/edition/' + id;
            }
        })
    }
}

document.querySelectorAll('.linkedRow').forEach(element => new TableLink(element));