import {trigger} from "../utils/Trigger";

export default class Select {

    constructor(select) {
        if (select.__SelectInstance__ instanceof Select) {
            return select.__SelectInstance__;
        }

        this.select  = select;
        this.wrapper = this.select.closest('.field-wrapper');
        this.cursor  = 0;
        this.search  = '';

        this.debouncer = new Map();

        if (this.wrapper === null) {
            return;
        }

        this.optWrapper = this.wrapper.querySelector('.field-wrapper__select-options');
        this.label      = this.wrapper.querySelector('label');
        this.selection  = this.wrapper.querySelector('.field-wrapper__select-selected');
        this.options    = [...this.optWrapper.querySelectorAll('.field-wrapper__select-option')]
            .filter(option => !option.classList.contains('hidden'));

        this.listen();

        this.select.__SelectInstance__ = this;
    }

    listen() {
        const observer = new MutationObserver(() => {
            this.options = [...this.optWrapper.querySelectorAll('.field-wrapper__select-option')]
                .filter(option => !option.classList.contains('hidden'));
        });

        $(this.wrapper)
            .on('focus', this.focus.bind(this))

            .on('focusout', this.focusout.bind(this))

            .on('click', this.toggle.bind(this))

            .on('keydown', this.keydown.bind(this))

            .on('change', 'select', this.change.bind(this))

            .on('click', '.field-wrapper__select-option', event => {
                this.doSelect(event.currentTarget.dataset.value);
            });

        observer.observe(this.optWrapper, {childList: true});
    }

    doSelect(value) {
        const option = [...this.select.options]
            .filter(option => option.value === value)
            .shift();

        if (option) {
            this.selection.innerText = option.text
            this.select.value        = option.value;
            trigger(this.select, 'change');
        }
    }

    focus() {
        if (!this.select.disabled) {
            this.wrapper.classList.add('focus');
        }
    }

    focusout() {
        this.wrapper.classList.remove('focus');

        if (this.isOpen()) {
            this.close();
        }
    }

    keydown(event) {
        if (!this.wrapper.classList.contains('focus')) {
            return;
        }

        const key = event.charCode || event.keyCode || 0;

        if ([38, 40].indexOf(key) !== -1) {
            event.preventDefault();
        }

        if (key === 38 && this.isOpen()) {
            this.upHandler();
        }

        if (key === 40) {
            this.downHandler();
        }

        if (key === 27 && this.isOpen()) {
            return this.close();
        }

        if (key === 13) {
            const selected = this.options.filter(option => option.classList.contains('hover')).shift();

            if (selected) {
                selected.click();
            }
        }

        if (key >= 48 && key <= 90) {
            this.search += event.key.toLowerCase();
            this.debouncerHandler('search', setTimeout(this.searchHandler.bind(this), 400));
        }
    }

    change() {
        if (this.select.value) {
            this.wrapper.classList.add('fullfilled');
        } else {
            this.selection.innerHTML = '';
            this.wrapper.classList.remove('fullfilled')
        }

        trigger(this.wrapper, 'select.change');
    }

    open() {
        if (this.options.length && !this.select.disabled) {
            this.cursor = 0;
            this.options[this.cursor].classList.add('hover');
            this.wrapper.classList.add('open', 'focus');

            trigger(this.wrapper, 'select.open');
        }
    }

    close() {
        this.wrapper.classList.remove('open', 'focus');
        this.options.forEach(option => option.classList.remove('hover'));

        trigger(this.wrapper, 'select.close');
    }

    toggle() {
        this.isOpen() ? this.close() : this.open();

        return false;
    }

    searchHandler() {
        this.options.forEach(option => option.classList.remove('hover'));

        const option = this.options.filter(option => option.textContent.toLowerCase().includes(this.search)).shift();
        if (option) {
            this.cursor = this.options.indexOf(option);
            option.classList.add('hover');

            this.scrollTo(option);
        }

        this.search = '';
    }

    upHandler() {
        this.options.forEach(option => option.classList.remove('hover'));

        if (this.cursor > 0) {
            this.cursor--;
        }

        this.options[this.cursor].classList.add('hover');

        if (!this.isVisible(this.options[this.cursor])) {
            this.debouncerHandler('scroll-up', setTimeout(this.scrollTo.bind(this, this.options[this.cursor]), 100));
        }
    }

    downHandler() {
        if (!this.isOpen()) {
            return this.open();
        }

        this.options.forEach(option => option.classList.remove('hover'));

        if (this.cursor < this.options.length - 1) {
            this.cursor++;
        }

        this.options[this.cursor].classList.add('hover');

        if (!this.isVisible(this.options[this.cursor])) {
            this.debouncerHandler('scroll-down', setTimeout(this.scrollTo.bind(this, this.options[this.cursor]), 100));
        }
    }

    debouncerHandler(key, fn) {
        if (this.debouncer.has(key)) {
            clearTimeout(this.debouncer.get(key));
            this.debouncer.delete(key);
        }

        this.debouncer.set(key, fn);
    }

    isOpen() {
        return this.wrapper.classList.contains('open');
    }

    isVisible(element) {
        let top    = this.optWrapper.scrollTop;
        let bottom = top + this.optWrapper.getBoundingClientRect().height;

        let elemTop    = element.offsetTop;
        let elemBottom = elemTop + element.getBoundingClientRect().height;

        return (elemBottom <= bottom) && (elemTop >= top);
    }

    clean() {
        [this.select, this.optWrapper, this.selection].forEach(element => {
            [...element.childNodes].forEach(child => element.removeChild(child));
        });
    }

    scrollTo(option, correction = 0) {
        const top = option.offsetTop + correction;

        this.optWrapper.scrollTo({top: top, left: 0, behavior: 'smooth'});
    }
}