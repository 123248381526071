export const sidebarOperations = () => {
    let steps = document.querySelectorAll('.sidebar-operation__list__item:not([data-popin])');
    let tabs  = document.querySelectorAll('.operation-tab');

    for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        step.addEventListener('click', () => {
            $('.tab.active').removeClass('active');

            step.classList.add('active');

            for (let x = 0; x < tabs.length; x++) {
                if (tabs[x].classList.contains('active')) {
                    tabs[x].classList.remove('active')
                }
            }
            tabs[i].classList.add('active');

            $('body').trigger('tab.changed');
        })
    }
}