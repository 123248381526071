import Select from "../form/Select";
import Checkbox from "../form/Checkbox";
import File from "../form/File";
import Input from "../form/Input";
import Date from "../form/Date";

export const htmlToElement = (html) => {
  const template = document.createElement('template');

  html               = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;

  return template.content.firstChild;
};

export const isFocusable = element => {
  element = $(element);

  const tabIndex = isNaN(+element.prop('tabindex')) ? -1 : +element.prop('tabindex');

  return element.is(':visible') && !element.is(':disabled') && element.is(':input') && tabIndex > -1;
};

export const numberWithSpaces =  x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;');
};

export const toFixed = (num, precision) => {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
};

export const instanciate = (input) => {
  if (input instanceof HTMLSelectElement) {
    return new Select(input);
  }

  switch (input.getAttribute('type')) {
    case 'checkbox':
    case 'radio':
      return new Checkbox(input);

    case 'text':
      if (input.classList.contains('datepicker')) {
        return new Date(input);
      } else {
        return new Input(input);
      }

    case 'file':
      return new File(input);

    default:
      return new Input(input);
  }
}

export const updateStepIndex = (match) => {
  [...document.querySelectorAll('.step-title__span')]
    .filter(span => span.innerText.match(match))
    .forEach((span, index) => span.innerText = match + (index + 1));
}

/**
 * 
 * @param {*} field 
 * @param {boolean} isVisible 
 */
export const handleFieldDisplay = (field, isVisible) => {
  const attrAction = isVisible ? 'removeAttr' : 'attr';
  const action     = isVisible ? 'remove' : 'add';
  
  field[attrAction]('disabled', !isVisible);
  field[action+"Class"]('hidden');
  field.find('input')[attrAction]('disabled', !isVisible);
  field.parent()[action+"Class"]('hidden');
}
