export class State {

    constructor(element) {
        if (element === undefined) {
            throw new ReferenceError('There is no filter')
        }

        this.element    = element;
        this.filter     = element.querySelector('.state-filter__filter');
        this.dropdown   = element.querySelector('.state-filter__dropdown');

        this.listen();
    }

    listen() {
        document.addEventListener('click', event => {
            if (this.element.contains(event.target) && !this.dropdown.contains(event.target)) {
                this.toggle()
            } else if (!this.dropdown.contains(event.target)) {
                this.close()
            }
        });

        $(this.dropdown).on('change', 'input[type=checkbox]', this.change);
    }

    change() {
        $(window).trigger('filter-change');
    }

    toggle() {
        this.element.classList.contains('open') ? this.element.classList.remove('open') : this.element.classList.add('open')
    }

    close() {
        if (this.element.classList.contains('open')) {
            this.element.classList.remove('open')
        }

    }
}