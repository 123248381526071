export class SubMenu {

    constructor(element) {
        if (element === undefined) {
            throw new ReferenceError('The property is undefined')
        }

        this.element = element;
        this.listen();
    }

    listen() {
        this.element.addEventListener('click', this.toggle.bind(this), false);
    }

    toggle(event) {
        if (event.target.closest('.menu-list__submenu')) {
            return;
        }

        if (event.target.closest('.menu-list__item__submenu')) {
            this.element.classList[this.element.classList.contains('open') ? 'remove' : 'add']('open');
        }
    }
}
