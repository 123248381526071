import Date from './Date';
import Select from './Select';
import Input from './Input';
import PictoSelect from './PictoSelect';
import ChainedList from './ChainedList';
import Upload from './Upload';
import File from './File';
import Search from './Search';
import Checkbox from './Checkbox';
import {Autocomplete} from "./Autocomplete";

export class Form {

    constructor() {
        document.querySelectorAll('[type=text], [type=password], [type=number], [type=email]').forEach(input => new Input(input));

        document.querySelectorAll('.field-wrapper__select-input').forEach(select => new Select(select));

        document.querySelectorAll('input[type=file]').forEach(input => new File(input));

        document.querySelectorAll('input[type=search]').forEach(input => new Search(input));

        document.querySelectorAll('input[type=checkbox], input[type=radio]').forEach(input => new Checkbox(input));

        document.querySelectorAll('.datepicker').forEach(element => new Date(element));

        document.querySelectorAll('.dropzone').forEach(dropzone => new Upload(dropzone));

        document.querySelectorAll('.picto-select').forEach(select => new PictoSelect(select));

        document.querySelectorAll('.chained-list').forEach(element => new ChainedList(element));

        document.querySelectorAll('.field-wrapper__autocomplete').forEach(input => new Autocomplete(input));
    }
}