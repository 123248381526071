import isFunction from 'lodash/isFunction';

export default class Fetch {
    constructor() {
        this.hooks = new Map();
    }

    before(callback) {
        if (isFunction(callback)) {
            this.hooks.set('before', callback);
        }

        return this;
    }

    promise(callback) {
        if (isFunction(callback)) {
            this.hooks.set('promise', callback);
        }

        return this;
    }

    finally(callback) {
        if (isFunction(callback)) {
            this.hooks.set('finally', callback);
        }

        return this;
    }

    reset() {
        [...this.hooks.keys()].forEach(key => this.hooks.delete(key));
    }

    async load(path, options) {
        options = options || {};

        const ctrl = new AbortController();

        if (!options.hasOwnProperty('signal')) {
            options = Object.assign(options, {signal: ctrl.signal});
        }

        if (this.hooks.has('before')) {
            this.hooks.get('before')();
        }

        const promise = fetch(path, options).catch(error => {
        });

        if (this.hooks.has('promise')) {
            this.hooks.get('promise')(promise, ctrl);
        }

        if (this.hooks.has('finally')) {
            promise.finally(() => (this.hooks.get('finally')(), this.reset()));
        }

        return await promise;
    }

    async text(path, options) {
        const response = await this.load(path, options);

        if (response.ok) {
            return response.text();
        }
    }

    async json(path, options) {
        const response = await this.load(path, options);

        if (response.ok) {
            return response.json();
        }
    }
}