export class Date {

    constructor(element) {
        element.flatpickr({
            mode: 'range',
            dateFormat: "d-m-Y",
            showMonths: 2,
            locale: 'fr',
            onChange: function(selectedDates, dateStr, instance) {
                $(window).trigger('filter-change');
            },
        });
    }
}