import Popin from "../utils/Popin";

export default class BulkPage {
    constructor() {
        // Check if right page
        if ($('#bulk[name="operation_bulk"]').length === 0) {
            return;
        }

        this.popinsId = ['waiting_proof', 'comment', 'ineligible', 'cancel'];
        this.popins   = new Map();

        this.popinsId.forEach(id => {
            const popinEl = document.getElementById(id);
            if (popinEl) {
                this.popins.set(id, new Popin(popinEl));
            }
        });


        this.listen();
    }

    listen() {
        var checkbox = $('#delete_validator');
        $('#operation_bulk_delete').on('click', event => {
            if (!checkbox.is(':checked')) {
                event.preventDefault();
                document.querySelector('.popin-error-message').innerHTML = '* Vous devez cocher la case pour supprimer';
            }
        });

        $('#operation_bulk_companyNamePro').on('selected', function(e, data) {
            ['siret', 'address', 'city', 'zipcode'].forEach(prop => $('#operation_bulk_'+ prop + 'Pro').val(data[prop]).trigger('change'));
        });

        $('#operation_bulk_companyNameBenef').on('selected', function(e, data) {
            ['siren', 'address', 'city', 'zipcode'].forEach(prop => $('#operation_bulk_'+ prop + 'Benef').val(data[prop]).trigger('change'));
        });

        $('#operation_bulk_siteName').on('selected', function(e, data) {
            ['address', 'city', 'zipcode'].forEach(prop => $('#operation_bulk_site' + (prop.charAt(0).toUpperCase() + prop.slice(1))).val(data[prop]).trigger('change'));
        });

        $('#operation_bulk_state_state').on('change', (e) => this.onStateChange(e));

        this.popins.forEach((popin) => {
            $(popin.element).on('popin.closed', (e, popin, target) => {
                if (target && (target.classList.contains('btn-save-form'))) {
                    return;
                }

                const inputs = $(popin.element).find('input, textarea, select');
                inputs.each((i, input) => {
                    input.value = '';
                    input.checked = false;
                    CKEDITOR.instances.operation_bulk_state_requestProof_description.setData('');
                });
            });
        });
    }


    onStateChange(e) {
        const state = e.target.value;
        let popin = this.popins.has(state) ? this.popins.get(state) : this.popins.get('comment');

        if (state === 'waiting_complement') {
            popin = this.popins.get('waiting_proof');
        }

        popin.open();
    }
}
