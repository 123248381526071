/**
 * newColumn
 * 
 * @param {*} element 
 */
export const newColumn = (element) => {
  const dropdown = element.querySelector('.column-filter__dropdown');

  if (dropdown) {
    listen(element, dropdown);
  }
  
}

/**
 * listen
 * 
 * @param {*} element 
 * @param {*} dropdown 
 */
const listen = (element, dropdown) => {
  document.addEventListener('click', event => {
      if (element.contains(event.target) && !dropdown.contains(event.target)) {
          toggle(element);
      } else if (!dropdown.contains(event.target)) {
          close(element);
      }
  });

  $(dropdown).on('change', 'input[type=checkbox]', change);
}

/**
 * change
 */
const change = () => {
  $(window).trigger('filter-change');
}

/**
 * toggle
 * 
 * @param {*} element 
 */
const toggle = (element) => {
  element.classList.contains('open') ? element.classList.remove('open') : element.classList.add('open')
}

/**
 * close
 * 
 * @param {*} element 
 */
const close = (element) => {
  if (element.classList.contains('open')) {
      element.classList.remove('open')
  }
}