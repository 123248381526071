export class SmoothScroll {
    constructor() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            if (anchor.getAttribute('href') !== '#' && !anchor.classList.contains('no-smoothscroll')) {
                anchor.addEventListener('click', event => {
                    event.preventDefault();

                    const target = document.querySelector(anchor.getAttribute('href'));

                    if (target) {
                        target.scrollIntoView({behavior: 'smooth'});
                    }
                });
            }
        });
    }
};