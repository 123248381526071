export default class Anchor {

    constructor(anchor) {
        if (anchor.__InputInstance__ instanceof Anchor) {
            return anchor.__InputInstance__;
        }

        this.anchor  = anchor;
        this.pattern = this.anchor.getAttribute('href');
        this.targets = document.querySelectorAll(`${this.pattern}, ${this.pattern}Content`);

        this.listen();

        this.anchor.__AnchorInstance__ = this;
    }

    listen() {
        this.anchor.addEventListener('click', this.toggle.bind(this));
    }

    toggle(event) {
        event.preventDefault();
        event.stopPropagation();

        const currentMenu    = document.querySelector('.tab.active');
        const currentContent = document.querySelector('.operation-tab.active');

        if (currentMenu && currentMenu.classList.contains('active')) {
            currentMenu.classList.remove('active');
        }

        if (currentContent && currentContent.classList.contains('active')) {
            currentContent.classList.remove('active');
        }

        this.targets.forEach(target => target.classList.add('active'));

        $('body').trigger('anchor.changed');
    }
}

