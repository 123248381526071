export class Dashboard {

    constructor() {
        this.inputRange = document.getElementById('form_date');
        if(this.inputRange) {
            this.statsForm();
        }
    }

    statsForm() {
        const fp = this.inputRange._flatpickr;

        const url = new URL(window.location.href);
        const dateFrom = url.searchParams.get("dateFrom");
        const dateTo = url.searchParams.get("dateTo");

        if(dateFrom && dateTo) {
            $(this.inputRange).closest('.field-wrapper').addClass('fullfilled');
            fp.setDate([dateFrom, dateTo]);
        }

        fp.set('onClose', function(selectedDates, dateStr, instance) {
            const dateFrom = fp.formatDate(selectedDates[0], 'Y-m-d');
            const dateTo = fp.formatDate(selectedDates[1], 'Y-m-d');

            window.location.href = `${window.location.href.split('?')[0]}?dateFrom=${dateFrom}&dateTo=${dateTo}`;
        });
    }
}