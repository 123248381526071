import 'babel-polyfill/dist/polyfill.min.js';
import { Form } from './form/Form';
import { Dropdown } from './utils/Dropdown';
import { Document } from './utils/Document';
import { Subtab } from './subtab';
import { BurgerMenu } from './burgerMenu';
import { SmoothScroll } from './form/SmoothScroll';
import { Dashboard } from "./pages/Dashboard";
import { Sticky } from './utils/Sticky';
import { TableLink } from './utils/TableLink';
import { FormRadioChoice } from './form/FormRadioChoice';
import { Date } from "./filters/Date";
import { Filter } from "./filters/Filter";
import { State } from "./filters/State";
import { SubMenu } from './utils/Submenu';
import BulkPage from "./pages/Bulk";
import { newColumn } from './filters/column';
import Prime from "./pages/Prime";
import { sidebarOperations } from './pages/sidebarOperations';

import Anchor from "./form/Anchor";
import cssVars from 'css-vars-ponyfill/dist/css-vars-ponyfill.min';

import 'parsleyjs/dist/parsley.min';
import 'parsleyjs/dist/i18n/fr';
import 'select2/dist/js/select2.full.min';

import './utils/jQuerySupport';
import './utils/Polyfill';
import Popin from "./utils/Popin";

class Index {
  constructor() {
    cssVars();
    new Form();
    new Subtab();
    new BurgerMenu();
    new SmoothScroll();
    new Dropdown(document.querySelectorAll('.dropdown .btn-dropdown'));

    sidebarOperations();

    new Sticky();
    new Dashboard();
    new Document();
    new TableLink();
    new BulkPage();
    new Prime();

        const autocomplete = document.getElementById('impersonate');
        const company      = document.getElementById('impersonate_company');
        const link         = document.getElementById('impersonatePath');

    if (autocomplete && link) {
      let path = link.getAttribute('href');
      let url = path.split('?');
      let href = url[0];
      let params = typeof url[1] === 'undefined' ? false : url[1];

            function changeAutocomplete() {
                if (!params) {
                    link.setAttribute('href', href + '/' + autocomplete.value + (company ? '?company=' + company.value : ''))
                } else {
                    link.setAttribute('href', href + '/' + autocomplete.value + '?' + params + (company ? '&company=' + company.value : ''));
                }
            }

            $(autocomplete).on('change', () => {
                if (company) {
                    $.ajax({
                        url: $(autocomplete).attr('data-ajax--url') + '&field_name=impersonate_company&impersonate=' + $(autocomplete).val(),
                        method: 'GET',
                        success: (data) => {
                            $('#impersonate_company option').hide();
                            $('#impersonate_company').parent().find('.field-wrapper__select-options li').hide();
                            $('#impersonate_company').parent().find('.field-wrapper__select-selected').html('');
                            for (var i = 0; i < data.results.length; i++) {
                                $('#impersonate_company option[value=' + data.results[i] + ']').show();
                                $('#impersonate_company').parent().find('.field-wrapper__select-options li[data-value=' + data.results[i] + ']').show();
                            }

                            if (1 === data.results.length) {
                                $('#impersonate_company option[value=' + data.results[0] + ']').attr('selected', 'selected');
                                $('#impersonate_company').parent().find('.field-wrapper__select-selected').html($('#impersonate_company option[value=' + data.results[0] + ']').text());
                                $('#impersonate_company').parent().find('.field-wrapper').addClass('fullfilled');
                                changeAutocomplete();
                            }
                        }
                    });
                }

                changeAutocomplete();
            });

            if (company) {
                // autocomplete.setAttribute('data-req_params', JSON.stringify({'company': 'impersonate_company'}));
                $(company).on('change', () => {
                    changeAutocomplete();
                });
            }
        }

    document.querySelectorAll('.rangeDate').forEach(item => new Date(item));

    document.querySelectorAll('.filter-search').forEach(item => new Filter(item));

    document.querySelectorAll('.linked-choice').forEach(item => new FormRadioChoice(item));

    document.querySelectorAll('.state-filter').forEach(item => new State(item));

    document.querySelectorAll('.column-filter').forEach(item => newColumn(item));

        document.querySelectorAll('.filters-displayed').forEach(item => newColumn(item));

        document.querySelectorAll('.filters-displayed').forEach(item => newColumn(item));

        document.querySelectorAll('.menu-list__item__submenu').forEach(item => new SubMenu(item));

    document.querySelectorAll('.anchor').forEach(anchor => new Anchor(anchor));

    document.querySelectorAll('[data-popin]').forEach(link => new Popin($(link.dataset.popin).get(0)));

    new Popin(document.getElementById('rai'));

    if (null !== document.getElementById('popup-password-expired')) {
      new Popin(document.getElementById('popup-password-expired'));
    }
  }
}

document.addEventListener('DOMContentLoaded', () => new Index(), false);
