import {trigger} from "../utils/Trigger";
import Fetch from "../fetch";
import item from "../../../templates/components/form/result/item.twig";
import {htmlToElement} from '../utils/utils';

export class Autocomplete {
    constructor(input) {
        if (input.__AutocompleteInstance__ instanceof Autocomplete) {
            return input.__AutocompleteInstance__;
        }

        this.cursor    = 0;
        this.input     = input;
        this.property  = input.dataset.property;
        this.wrapper   = this.input.parentNode;
        this.label     = this.wrapper.querySelector('label');
        this.debouncer = new Map();
        this.items     = new Map();
        this.fetch     = new Fetch();

        this.resultsWrapper = this.wrapper.querySelector('.field-wrapper__autocomplete-results');
        this.results        = [...this.resultsWrapper.querySelectorAll('.field-wrapper__autocomplete-result')];

        this.listen();

        this.input.__AutocompleteInstance__ = this;
    }

    listen() {
        const observer = new MutationObserver(() => {
            this.results = [...this.resultsWrapper.querySelectorAll('.field-wrapper__autocomplete-result')];

            this.open();
        });

        $(this.wrapper)
            .on('click', '.field-wrapper__autocomplete-result', this.select.bind(this))

            .on('mousedown', this.mousedown.bind(this))

            .on('click', 'label', this.click.bind(this))

            .on('focus', 'input', this.focus.bind(this))

            .on('focusout', this.focusout.bind(this))

            .on('blur', 'input', this.blur.bind(this))

            .on('change', 'input', this.change.bind(this))

            .on('keydown', this.keydown.bind(this));

        observer.observe(this.resultsWrapper, {childList: true});
    }

    select(event) {
        const result = this.items.get(event.currentTarget);

        this.input.value = result.hasOwnProperty(this.property) ?
            result[this.property] : result.text;

        $(this.input).trigger('selected', result);

        this.focusout();
    }

    mousedown(event) {
        if (this.wrapper.classList.contains('focus')) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }

    click() {
        trigger(this.input, 'focus');
    }

    blur() {
        trigger(this.input, 'focusout');
    }

    focus() {
        if (!this.input.disabled) {
            this.wrapper.classList.add('focus');
        }
    }

    focusout() {
        this.wrapper.classList.remove('focus');

        if (this.isOpen()) {
            this.close();
        }
    }

    change() {
        this.wrapper.classList[this.input.value !== '' ? 'add' : 'remove']('fullfilled');
    }

    debouncerHandler(key, fn) {
        if (this.debouncer.has(key)) {
            clearTimeout(this.debouncer.get(key));
            this.debouncer.delete(key);
        }

        this.debouncer.set(key, fn);
    }

    keydown(event) {
        const key = event.charCode || event.keyCode || 0;

        if ([38, 40].indexOf(key) !== -1) {
            event.preventDefault();
        }

        if (key === 38 && this.isOpen()) {
            return this.upHandler();
        }

        if (key === 40) {
            return this.downHandler();
        }

        if (key === 27 && this.isOpen()) {
            return this.close();
        }

        if (key === 13) {
            const selected = this.results.filter(result => result.classList.contains('hover')).shift();

            if (selected) {
                return selected.click();
            }
        }

        if (this.input.value) {
            this.debouncerHandler('search', setTimeout(this.searchHandler.bind(this), 300));
        }
    }

    upHandler() {
        this.results.forEach(option => option.classList.remove('hover'));

        if (this.cursor > 0) {
            this.cursor--;
        }

        this.results[this.cursor].classList.add('hover');

        if (!this.isVisible(this.results[this.cursor])) {
            this.debouncerHandler('scroll-up', setTimeout(this.scrollTo.bind(this, this.results[this.cursor]), 100));
        }
    }

    downHandler() {
        if (!this.isOpen()) {
            return this.open();
        }

        this.results.forEach(option => option.classList.remove('hover'));

        if (this.cursor < this.results.length - 1) {
            this.cursor++;
        }

        this.results[this.cursor].classList.add('hover');

        if (!this.isVisible(this.results[this.cursor])) {
            this.debouncerHandler('scroll-down', setTimeout(this.scrollTo.bind(this, this.results[this.cursor]), 100));
        }
    }

    searchHandler() {
        this.fetch.json(this.getUrl()).then(response => {
            this.clean();

            if ([] !== response) {
                response.results.forEach(this.addItem.bind(this));
            }
        });
    }

    addItem(result) {
        const element = htmlToElement(item({value: result.id, text: result.text}));

        this.items.set(element, result);
        this.resultsWrapper.insertAdjacentElement('beforeend', element);
    }

    open() {
        if ([this.wrapper, this.input].indexOf(document.activeElement) === -1) {
            return;
        }

        if (this.results.length) {
            this.cursor = 0;
            this.results[this.cursor].classList.add('hover');
            this.wrapper.classList.add('open', 'focus');
        }
    }

    close() {
        this.wrapper.classList.remove('open', 'focus');
        this.results.forEach(option => option.classList.remove('hover'));
        this.input.blur();

        trigger(this.wrapper, 'select.close');
    }

    isOpen() {
        return this.wrapper.classList.contains('open');
    }

    isVisible(element) {
        let top    = this.resultsWrapper.scrollTop;
        let bottom = top + this.resultsWrapper.getBoundingClientRect().height;

        let elemTop    = element.offsetTop;
        let elemBottom = elemTop + element.getBoundingClientRect().height;

        return (elemBottom <= bottom) && (elemTop >= top);
    }

    clean() {
        [this.resultsWrapper].forEach(element => {
            if (this.items.has(element)) {
                this.items.delete(element);
            }

            [...element.childNodes].forEach(child => element.removeChild(child));
        });
    }

    scrollTo(option, correction = 0) {
        const top = option.offsetTop + correction;

        this.resultsWrapper.scrollTo({top: top, left: 0, behavior: 'smooth'});
    }

    getUrl() {
        const url = new URL(this.input.dataset.path, window.location.protocol + '//' +window.location.hostname);
        
        url.searchParams.append('field_name', this.input.dataset.name);
        url.searchParams.append('q', this.input.value);

        return url.toString();
    }
}