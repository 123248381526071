import smoothscroll from "smoothscroll-polyfill";

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

window.__forceSmoothScrollPolyfill__ = true;

smoothscroll.polyfill();