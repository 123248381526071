export class Subtab {

    constructor() {
        this.tabs    = [...document.querySelectorAll('.submenu__item__link')];
        this.current = this.tabs.filter(tab => (new URL(tab.href)).hash === window.location.hash).shift() || this.tabs[0];

        if (this.current) {
            this.current.classList.add('active');
        }

        this.listen();

        this.jumpToError();
    }

    listen() {
        $(this.tabs).on('click', this.toggle.bind(this));
    }

    jumpToError() {
        const error = document.querySelector('.field-wrapper.error');

        if (error) {
            $(error).closest('.submenu__item').first().click();
        }
    }

    toggle(event) {
        if (event.target.isEqualNode(this.current)) {
            return;
        }

        if (this.current) {
            this.current.classList.remove('active');
        }

        this.current = event.target;
        this.current.classList.add('active');
    }
};


