
export default class Loader {
    constructor(additionalClass = '') {
        this.loader = $('<div class="loader-container ' + additionalClass +'"><div class="lds-dual-ring" ></div></div>');
    }

    add(element) {
        $(element).append(this.loader);
    }

    remove() {
        this.loader.remove();
    }
}