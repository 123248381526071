export class BurgerMenu {
    constructor() {

        let openBurger = document.querySelector('.burger');
        let closeBurger = document.querySelector('.close');
        let burgerMenu = document.querySelector('.burger-menu');
        let burgerItems = document.querySelectorAll('.burger-menu__list__item');
        let overlay = document.querySelector('.overlay');
        let container = document.querySelector('.main-container');


        if(openBurger !== null) {
            openBurger.addEventListener('click', e => {
                burgerMenu.classList.add('show');
                container.classList.add('disable-scroll');
                overlay.classList.remove('hidden');
            });
        }

        if(openBurger !== null && closeBurger.length > 0) {
            closeBurger.addEventListener('click', e => {
                burgerMenu.classList.remove('show');
                container.classList.remove('disable-scroll');
                overlay.classList.add('hidden')
            });
        }

        burgerItems.forEach(item => {
            item.addEventListener('click', e => {
                burgerMenu.classList.remove('show');
                container.classList.remove('disable-scroll');
                overlay.classList.add('hidden')
            })
        });
    }
}

