
function trigger(element, name, delegate, context) {
    delegate = delegate || false;

    const event = document.createEvent('Event');

    event.initEvent(name, true, true);

    element.dispatchEvent(event);

    if (delegate === true) {
        $(element).trigger(name, context);
    }
}

export { trigger }