import Fetch from "../fetch";
import Select from "./Select";
import PictoSelect from "./PictoSelect";
import {trigger} from "../utils/Trigger";

export default class ChainedList {

    constructor(select) {
        if (select.__ChainedListInstance__ instanceof ChainedList) {
            return select.__ChainedListInstance__;
        }

        this.select = select;
        this.target = document.querySelector(this.select.dataset.target);
        this.fetch  = new Fetch();

        this.fetch.before(() => trigger(this.select, 'chained.list.before.load', true, [this]));

        this.listen();

        this.select.__ChainedListInstance__ = this;
    }

    listen() {
        this.select.addEventListener('change', this.change.bind(this));
    }

    change() {
        const path = this.select.dataset.path;

        this.fetch.json(path.replace(/{id}/g, this.select.value)).then(data => {
            $(this.target).html(data.options);

            if (this.target.__SelectInstance__ instanceof Select) {
                $(this.target.__SelectInstance__.optWrapper).html(data.list);
            }

            if (this.target.__PictoSelectInstance__ instanceof PictoSelect) {
                $(this.target.__PictoSelectInstance__.pictoWrapper).html(data.images);
            }

            setTimeout(() => {
                trigger(this.select, 'chained.list.after.load', true)
            }, 100);
        });
    }
}