export class Dropdown {

    constructor(dropdowns) {

        Array.prototype.forEach.call(dropdowns, dropdown => {
            dropdown.addEventListener('click', event => {
                event.preventDefault();
                event.stopPropagation();

                this.open(dropdown);
            });
        });
    }

    /**
     * @param element
     */
    open(element) {
        if (element) {
            const content = element.nextElementSibling;

            if (!content.classList.contains('open')) {
                content.classList.add('open');

                document.addEventListener('click', event => {
                    content.classList.remove('open');

                    event.currentTarget.removeEventListener(event.type, () => {});
                });
            } else {
                content.classList.remove('open');
            }
        }
    }
}