
export default class Search {

    constructor(input) {
        if (input.__FileInstance__ instanceof Search || input.disabled) {
            return input.__InputInstance__;
        }

        this.input = input;
        this.form  = this.input.closest('form');

        this.listen();

        this.input.__SearchInstance__ = this;
    }

    listen() {
        this.input.addEventListener('search', () => this.form.submit());
    }
}