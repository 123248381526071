import flatpickr from 'flatpickr';
import {French} from '../i18n/flatpickr-fr';


export default class Date {

    constructor(element) {
        if (element.__DateInstance__ instanceof Date) {
            return element.__DateInstance__;
        }

        this.element = element;
        this.mode    = this.element.dataset.hasOwnProperty('mode') ? this.element.dataset.mode : 'single',
        this.wrapper = element.closest('.field-wrapper');
        this.label   = this.wrapper.querySelector('.field-wrapper__label-datepicker');

        this.instance = flatpickr(element, {
            locale:     French,
            static:     true,
            dateFormat: 'Y-m-d',
            altInput:   true,
            altFormat:  'd/m/Y',
            mode: this.mode,
            allowInput: true,
            clickOpens: false,
            onChange:   () => {
                if (this.instance.altInput.value) {
                    this.wrapper.classList.add('fullfilled');
                }
            },
            // ROZO-1626 : Bug when datepicker on bottom of the page, the scrollTo goes on top of the page...
            // onOpen: () => {
            //     const height = this.instance.calendarContainer.offsetHeight + this.instance.altInput.getBoundingClientRect().bottom + 10
            //
            //     let element = this.getFirstScrollableParent();
            //     let bottom  = element.getBoundingClientRect().bottom;
            //
            //     if (document.documentElement.clientHeight < element.clientHeight) {
            //         bottom  = document.documentElement.clientHeight;
            //         element = document.documentElement;
            //     }
            //
            //     if (bottom < height) {
            //         element.scrollTo(0, (height - bottom + 100));
            //     }
            // }
        });

        this.listen();

        this.element.__DateInstance__ = this;
    }

    listen() {
        $(this.wrapper)
            .on('change', 'input', this.change.bind(this))

            .on('mousedown', 'label', this.mousedown.bind(this))

            .on('focus', 'input', this.focus.bind(this))

            .on('focusout', 'input', this.focusout.bind(this))

            .on('click', 'label, input', this.click.bind(this))
    }

    change(event) {
        this.instance.setDate(event.target.value, false);
    }

    mousedown(event) {
        if (this.instance.isOpen) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
    }

    click() {
        if (!this.instance.isOpen) {
            this.instance.open();
        }

        if (!$(this.instance.altInput).is(':focus')) {
            this.instance.altInput.focus();
        }
    }

    focus() {
        this.wrapper.classList.add('focus');
    }

    focusout() {
        this.wrapper.classList.remove('focus');

        if (this.instance.isOpen && this.mode !== "range") {
            this.instance.close();
        }
    }

    getFirstScrollableParent() {
        const style               = getComputedStyle(this.instance.altInput);
        const excludeStaticParent = style.position === 'absolute';
        const overflowRegex       = /(auto|scroll|hidden)/;


        if (style.position === 'fixed') {
            return document.documentElement;
        }

        for (let parent = this.instance.altInput; (parent = parent.parentElement);) {
            let style = getComputedStyle(parent);

            if (excludeStaticParent && style.position === 'static') {
                continue;
            }

            if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
                return parent;
            }
        }

        return document.documentElement;
    }

    clear() {
        this.instance.clear();
    }
}
