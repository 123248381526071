export default class File {

    constructor(input) {
        if (input.__FileInstance__ instanceof File) {
            return input.__InputInstance__;
        }

        this.input   = input;
        this.wrapper = this.input.parentNode;
        this.label   = this.input.previousElementSibling;

        this.listen();

        this.input.__FileInstance__ = this;
    }

    listen() {
        this.input.addEventListener('change', () => this.label.innerHTML = this.input.files[0].name);

        this.wrapper.addEventListener('focus', this.focus.bind(this));

        this.wrapper.addEventListener('blur', this.blur.bind(this));

        this.wrapper.addEventListener('keydown', this.keydown.bind(this));
    }

    focus() {
        if (!this.input.disabled) {
            this.wrapper.classList.add('focus');
        }
    }

    blur() {
        this.wrapper.classList.remove('focus');
    }

    keydown(event) {
        if (!this.wrapper.classList.contains('focus')) {
            return;
        }

        const key = event.charCode || event.keyCode || 0;

        if ([32, 13].indexOf(key) !== -1) {
            event.preventDefault();

            this.input.click();
        }
    }
}