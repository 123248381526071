import {trigger} from "../utils/Trigger";

export default class PictoSelect {

    constructor(select) {
        if (select.__PictoSelectInstance__ instanceof PictoSelect || select.disabled) {
            return select.__PictoSelectInstance__;
        }

        this.select       = select;
        this.wrapper      = this.select.parentNode;
        this.pictoWrapper = this.wrapper.querySelector('.pictos');
        this.active       = this.pictoWrapper.querySelector('.active');

        this.listen();

        this.select.__PictoSelectInstance__ = this;
    }

    listen() {
        $(this.wrapper).on('click', '.pictos__container', this.click.bind(this));
    }

    click(event) {
        this.select.value = event.currentTarget.querySelector('.picto').dataset.value;

        trigger(this.select, 'change', true);

        if (this.active) {
            this.active.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
        this.active = event.currentTarget;
    }

    clean() {
        [this.select, this.pictoWrapper].forEach(element => {
            [...element.childNodes].forEach(child => element.removeChild(child));
        });
    }
}