import Dropzone from 'dropzone';
import listItem from '../../../templates/components/table/item.twig';

window.Dropzone = Dropzone;
window.Dropzone.autoDiscover = false;

const options = {
    dictDefaultMessage:           'Déposez votre fichier ici ou cliquez pour l\'envoyer.',
    dictFileTooBig:               'Le fichier est trop lourd : {{filesize}} MB. Poids maximum : {{maxFilesize}} MB.',
    dictInvalidFileType:          'Le format du fichier n\'est pas autorisé',
    dictCancelUpload:             'Annuler l\'envoi',
    dictCancelUploadConfirmation: 'Êtes-vous sûr de vouloir annuler l\'envoi ?',
    dictRemoveFile:               'Supprimer',
}

Dropzone.prototype.defaultOptions = Object.assign({}, Dropzone.prototype.defaultOptions, options);

export default class Upload {

    constructor(element) {
        this.list = $(element).closest('.popin-background').prev().find('.list');

        this.dropzone = new Dropzone(element, {
            createImageThumbnails: false,
            accept:                this.accept.bind(this),
            url:                   element.dataset.endpoint,
            uploadMultiple:        true,
            addRemoveLinks:        true,
            maxFilesize:           100,
            parallelUploads:       1,
            params:                JSON.parse(element.dataset.params),
        });

        this.listen();
    }

    get acceptedFiles() {
        return [
            'application/pdf',
            'application/x-pdf',
            'application/msword',
            "application/vnd.ms-powerpoint",
            'application/vnd.openxmlformats-',
            'officedocument.wordprocessingml.document',
            "officedocument.presentationml.presentation",
            'application/octet-stream',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-',
            'officedocument.spreadsheetml.sheet',
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/gif',
            'text/csv',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.text',
            'text/plain',
            'application/rtf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'xls',
            'xlsx',
            'doc',
            'docx',
            "video/mp4",
            "video/quicktime",
            "video/x-quicktime",
            "image/mov",
            "video/avi",
            "video/x-matroska",
            "" // @FIXME why is it empty sometimes ? tried it with a pptx
        ]
    }

    listen() {
        this.dropzone.on('success', (file, data) => {
            this.list.append(listItem({
                label:      file.name.split('.').slice(0, -1).join(''),
                iconName:   file.name.split('.').pop(),
                trashLink:  data.deleteLink,
                link:       data.downloadLink,
                attributes: [`data-uuid="${file.upload.uuid}"`],
            }));

            this.deleteLink = data.deleteLink;
            if (data.operationState) {
                $('#state_state').val(data.operationState);
                $('input[name="operation[state]"]').val(data.operationState)
                // we dont want to trigger change & show the comment popup
                $('form[name=state] .field-wrapper__select-selected').text(
                    $(`#state_state > option[value="${data.operationState}"]`).text()
                );
            }
        });

        this.dropzone.on('removedfile', file => {
            $.get(this.deleteLink, () => this.list.find(`li[data-uuid="${file.upload.uuid}"]`).remove());
        });

        this.dropzone.on('addedfile', this.thumbnailHandler.bind(this));
    }

    accept(file, done) {
        if (this.acceptedFiles.indexOf(file.type) === -1 && this.acceptedFiles.indexOf(file.name.split('.').pop()) === -1) {
            done(this.dropzone.defaultOptions.dictInvalidFileType);
        } else {
            done();
        }
    }

    thumbnailHandler(file) {
        if (file.type) {
            switch (file.type) {
                case 'application/pdf':
                case 'application/x-pdf':
                    this.dropzone.emit('thumbnail', file, '/build/images/pdf.png');
                    break;

                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    this.dropzone.emit('thumbnail', file, '/build/images/doc.png');
                    break;

                case 'application/vnd.ms-excel':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    this.dropzone.emit('thumbnail', file, '/build/images/xls.png');
                    break;

                case 'image/jpeg':
                case 'image/jpg':
                    this.dropzone.emit('thumbnail', file, '/build/images/jpg.png');
                    break;

                case 'image/png':
                case 'image/gif':
                    this.dropzone.emit('thumbnail', file, '/build/images/png.png');
                    break;

                case 'text/plain':
                    this.dropzone.emit('thumbnail', file, '/build/images/txt.png');
                    break;

                case 'text/csv':
                    this.dropzone.emit('thumbnail', file, '/build/images/csv.png');
                    break;

                default:
                    this.dropzone.emit('thumbnail', file, '/build/images/file.png');
            }
        } else {
            switch (file.name.split('.').pop()) {
                case 'text/csv':
                    this.dropzone.emit('thumbnail', file, '/build/images/csv.png');
                    break;

                case 'xls':
                case 'xlsx':
                    this.dropzone.emit('thumbnail', file, '/build/images/xls.png');
                    break;
            }
        }
    }
}