import Fetch from "../fetch";

export class Filter {
    constructor(element) {
        if (element === undefined) {
            throw new ReferenceError('There is no filter');
        }

        this.rangeFilter = element.querySelector('.filter__range');

       if (this.rangeFilter) {
        this.rangeFilter.addEventListener('keypress', (e) => {
            if ('Enter' === e.key) {
                $(window).trigger('filter-change');
            }
        });
       }

        if (!element.dataset.hasOwnProperty('path')) {
            return;
        }

        this.fetch        = new Fetch();
        this.element      = element;
        this.input        = element.querySelector('.filter__input');
        this.wrapper      = element.querySelector('.resultsFilter');
        this.counter      = element.querySelector('.filter__counter');

        this.listen();
    }

    listen() {
        $(this.wrapper).on('change', 'input[type=checkbox]', this.count.bind(this));

        this.input.addEventListener('focus', this.show.bind(this));

        this.input.addEventListener('click', this.displayList.bind(this));

        this.input.addEventListener('input', this.getInputValue.bind(this));

        this.input.addEventListener('blur', this.hide.bind(this));
    }

    displayList() {
      if (this.input.hasAttribute('data-autocomplete-minlength') && this.input.value === '') {
        this.fetchRequest(this.input.value);
      }
    }

    getInputValue() {
        let length = this.input.getAttribute('data-autocomplete-minlength');
        if (this.input.value.length >= (null !== length ? length : 3)) {
            this.fetchRequest(this.input.value);
        } else {
            if (this.controller instanceof AbortController) {
                this.controller.abort();
            }

            this.clear();
        }
    }

    fetchRequest(urlParameter) {
        if (this.controller instanceof AbortController) {
            this.controller.abort();
        }

        let url = new URL(this.element.dataset.path + '/' + urlParameter, window.location);

        [...this.wrapper.querySelectorAll('input[type=checkbox]')]
            .filter(item => item.checked)
            .forEach(item => url.searchParams.append('exclude[]', item.value));

        this.controller = new AbortController();

        this.fetch.text(url.toString(), {signal: this.controller.signal}).then(data => {
            this.clear();

            if (this.input.getAttribute('data-show-nocontent') && (!data || '' == data.trim())) {
                this.wrapper.insertAdjacentHTML('beforeend', `<div class="field-wrapper inline">
                    <input class="field-wrapper__checkbox" type="checkbox" disabled readonly />
                    <label class="field-wrapper__checkbox-label -noResult">Pas de correspondances</label>
                </div>`);
            } else {
                this.wrapper.insertAdjacentHTML('beforeend', data);
            }


            this.show();
        });
    }

    clear() {
        [...this.wrapper.querySelectorAll('input[type=checkbox]')]
            .filter(item => item.checked == false)
            .forEach(item => item.parentNode.parentNode.removeChild(item.parentNode));

        if (!this.wrapper.querySelectorAll('input[type=checkbox]').length) {
            this.wrapper.classList.remove('show');
        }
    }

    count() {
        const checkedItems = [...this.wrapper.querySelectorAll('input[type=checkbox]')].filter(item => item.checked);

        this.counter.classList.add('hidden');
        if (checkedItems.length) {
            this.counter.classList.remove('hidden');
            this.counter.firstChild.innerText = checkedItems.length;
        }

        $(window).trigger('filter-change');
    }

    show() {
      if (this.wrapper.querySelectorAll('input[type=checkbox]').length
              || this.wrapper.querySelectorAll('label').length) {
          this.wrapper.classList.add('show');
      }
    }

    hide() {
        if (this.input.value.length < 3) {
            this.clear();
        }

        this.wrapper.classList.remove('show');
    }
}