export class Sticky {

    constructor(element) {
        if (!element) {
            return;
        }

        this.element = element;
        this.header  = element.closest('.operation-header');
        this.map     = new Map([
            [this.element, 'sticky'],
            [this.header, 'bannerIsSticky']
        ]);

        this.listen();
    }

    listen() {
        window.onscroll = this.scroll.bind(this);
    }

    scroll() {
        const method = window.scrollY > this.element.getBoundingClientRect().top ? 'add' : 'remove';

        [this.element, this.header].forEach(item => item.classList[method](this.map.get(item)));
    }
}

document.querySelectorAll('.operation-header__banner').forEach(element => new Sticky(element));