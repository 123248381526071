import Input from "../form/Input";

export default class Prime {
    constructor() {
        this.input = $('.prime-sheet-collection');

        if (this.input.length > 0) {
            this.listen();
        }
    }

    listen() {
        $(this.input).collection({
            'after_add': function(collection, element) {
                $(collection).find('select.select2entity').select2entity();

                $(collection).find('input[type="number"]').each((index, input) => {
                    new Input(input);
                });
            },
        });
    }
}
