import Loader from "../loader";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Fetch from "../fetch";
import File from "../form/File";
import Select from "../form/Select";

export default class Popin {

    constructor(element) {

        if (!element) {
            return;
        }

        if (element.__PopinInstance__ instanceof Popin) {
            return element.__PopinInstance__;
        }

        this.element = element;
        this.loader  = new Loader();
        this.fetch   = new Fetch();
        this.triggerBy;

        this.listen();

        this.element.__PopinInstance__ = this;
    }

    get content() {
        return this.element.querySelector('.popin__content');
    }

    get footer() {
        return this.element.querySelector('.popin__footer');
    }

    load(link) {
        this.loader.add($(this.element).find('.popin').get(0));

        this.fetch.text(link).then(html => {
            const popin = this.element.querySelector('.popin');

            while(popin.firstChild) {
                popin.removeChild(popin.firstChild);
            }

            this.element.querySelector('.popin').insertAdjacentHTML('afterbegin', html);
            this.element.querySelectorAll('input').forEach(this.instanciate.bind(this));
            this.loader.remove.bind(this.loader);
        });
    }

    listen() {
        $(document)
            .on('click', `[data-popin="#${this.element.id}"]`, this.open.bind(this))

            .on('keydown', this.keydown.bind(this));

        $(this.element).on('click', '.popin__close-container, .popin__close', this.close.bind(this));
    }

    open(event) {
        document.body.classList.add('popin-open');

        if (event) {
            event.preventDefault();

            this.link = event.currentTarget.dataset.hasOwnProperty('load') ? event.currentTarget.dataset.load : false;
            if (this.link) {
                this.load(this.link);
            }
        }

        this.triggerBy = event ? event.currentTarget : undefined;

        this.element.classList.add('open');
        this.trigger('popin.opened');
    }

    close(e) {
        document.body.classList.remove('popin-open');

        if (this.link) {
            $(this.element).find('.popin').html('');
        }

        this.element.classList.remove('open');
        this.trigger('popin.closed', e.currentTarget);
    }

    keydown(event) {
        const key   = event.charCode || event.keyCode || 0;

        if (key === 27 && this.isOpen()) {
            return this.close();
        }
    }

    trigger(name, params) {
        $(this.element).trigger(name, [this, params]);
    }

    isOpen() {
        return this.element.classList.contains('open');
    }

    instanciate(input) {
        switch (input.getAttribute('type')) {
            case 'checkbox':
            case 'radio':
                return new Checkbox(input);

            case 'file':
                return new File(input);

            default:
                return new Input(input);
        }
    }
}
