import NotificationSuccess from '../../../templates/components/notification/notification.twig';
import NotificationDanger from '../../../templates/components/notification/notification--danger.twig';

export class Document {

    constructor() {
        $(document).on('click', '.trash-link', event => this.remove(event));
    }

    remove(event) {
        event.preventDefault();

        $.get($(event.currentTarget).data('delete'), (data) => {
            let notification = '';
            if (data.status === 'success') {
                notification = NotificationSuccess({
                    text: data.statusMsg
                });

                //Remove from list
                $(event.currentTarget).parent().fadeOut().remove();
            } else if (data.status === 'error') {
                notification = NotificationDanger({
                    text: data.statusMsg
                });
            }

            $(document.body).prepend(notification);
        });
    }
}