import {trigger} from "../utils/Trigger";

export class FormRadioChoice {

    constructor(item) {

        this.item   = item;
        this.fields = item.querySelectorAll('input');
        this.links  = [...document.querySelectorAll(`input[name='${item.dataset.linked}']`)];
        this.linked = undefined;

        this.listen();

        this.fields.forEach(field => field.__FormRadioChoiceInstance__ = this);
    }

    listen() {
        this.fields.forEach(input => input.addEventListener('change', this.doLink.bind(this)));
    }

    doLink(event) {
        event.target.classList.remove('trigger-by-form-radio-choice');

        if (this.links && event.target) {
            this.linked = this.links.filter(link => link.value !== event.target.value && !link.checked).shift();

            if (this.linked) {
                event.target.classList.add('trigger-by-form-radio-choice');
                this.linked.checked = true;
            }
        }
    }
}